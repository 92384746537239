<template>
  <ListItem :condensed="true">
    <div :class="containerClasses" class="w-full">
      <div class="flex items-center w-full">
        <p class="mb-0">
          {{ $f.truncate(item.cost_type_name || item.item_name || item.cost_item_name, 40) }}
        </p>
      </div>
      <div class="flex items-center justify-center" v-if="item.invoice_status">
        <Chip
          :pt="{ root: '!rounded' }"
          :ptOptions="{ mergeProps: true }"
          :class="billingStatusClasses"
          :label="billingMessage"
        ></Chip>
      </div>
      <div class="flex items-center justify-center w-full">
        <span class="ml-3 text-sm"> 1 item included </span>
      </div>
      <div
        class="flex items-center justify-center last:justify-end"
        v-if="settings.displayItemizedPricing"
      >
        <p class="mb-0">{{ $f.currency(item.item_gross) }}</p>
      </div>
    </div>
    <template v-if="showFooter" #footer>
      <slot name="footer" />
    </template>
  </ListItem>
</template>

<script setup>
import { computed, toRef, useSlots, defineProps } from 'vue'
import Chip from 'primevue/chip'
import usePayment from '@/components/composables/Payment'
import ListItem from './ListItem.vue'

const props = defineProps({
  item: {
    required: true
  }
})

const item = toRef(props, 'item')
const { settings } = usePayment()

const slots = useSlots()
const showFooter = computed(() => slots.footer && slots.footer().length > 0)

const containerClasses = computed(() => {
  let cols = 3
  if (settings.value.displayItemizedPricing) cols++
  if (item.value.invoice_status) cols++
  return `grid grid-cols-${cols} gap-2`
})

const billingStatusClasses = computed(() => {
  if (item.value.invoice_status === 'p') return '!bg-orange-100 !text-orange-900'
  return '!bg-blue-100 !text-blue-900'
})

const billingMessage = computed(() => {
  if (item.value.invoice_status === 'p') return 'Awaiting Payment'
  return 'Processing'
})
</script>
